import styles from './data.module.scss';
import { useTranslation } from 'react-i18next';
import values from './values.js'

const Data = () => {
	const [t] = useTranslation();

	return (
		<section id="data" className={styles.container}>
			<div className={styles.content_wrapper}>
				<ul className={styles.featlist}>
					{
						values.map((item, index) => {
            return (
              <li className={styles.item} key={index}>
                  <span className={styles.value}>
                    {t(`section.newhome.data.${item.value}`)}
                  </span>
                  <h5 className={styles.title}>
                    {t(`section.newhome.data.${item.title}`)}
                  </h5>
                  <p className={styles.description}>
                    {t(`section.newhome.data.${item.description}`)}
                  </p>
              </li>
            )
        })
					}
				</ul>
			</div>
		</section>
	);
};

export default Data;
