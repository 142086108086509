import { useTranslation } from 'react-i18next'
import styles from './home.module.scss'

const HomeComponent = ({ name, images }) => {

// const { id, title, subTitle, description, images } = home
const [t] = useTranslation()


  return (
    <section id={name} className={styles.container}>
    <div className={styles.content_wrapper}>
        <div className={styles.text_wrapper}>
            <h5>
                <b>{t(`section.${name}.home.title`)}</b>
            </h5>
            <h1 className={styles.title}>
                {t(`section.${name}.home.subTitle`).toUpperCase()}
            </h1>
            <p>
                {t(`section.${name}.home.description`)}
            </p>
        </div>
        <div className={styles.image_wrapper}>
            <img
                className={styles.image}
                alt={t(`section.${name}.home.noImage`)}
                src={images}
            />
        </div>
    </div>
</section>
  )
}

export default HomeComponent