

export const useSendFormData = () => {
    const sendDataForm = async (data) => {
      await fetch('https://hooks.slack.com/services/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', {
         method: 'POST',
         mode: 'no-cors',
         headers: {
            "Content-Type": "application/json",
          },
         body: JSON.stringify({
            text:`Nombre: ${data.Name}
                Email: ${data.Email} 
                Cargo: ${data.Position}
                Colegio: ${data.School} 
                Telefono: ${data.Phone}
                Pais: ${data.Country}
                Funciones: ${data.Functions}
                Opciones: ${data.Options}
                `
            })
       })
       .then(() => console.log('Request successful'))
       .catch((error) => console.log('error', error))
    }
  return { 
    sendDataForm
  }
}