import { useTranslation } from 'react-i18next'
import styles from './contact.module.scss'
import { Divider, NewContactForm } from '../../components'
import ImagesWrapper from './images-wrapper'

const ContactPage = () => {
  const [t] = useTranslation()
  const googleSheetUrl = 'https://script.google.com/macros/s/AKfycbz2Cfsws4Fe0zaGQPdSUq21mGFBlzEhDG8YyhZa_utZJLTTJIVVezHfyQlXGcmPinmCNA/exec'
  return (
    <main>
        <Divider color="green" className={styles.divider} reverse/>
        <section className={styles.container}>
          <ImagesWrapper/>
          <div className={styles.form_ebook}>
            <h1>{t('contact.title')}</h1>
            <NewContactForm 
              googleSheetUrl={googleSheetUrl}  
              alertMessageError={t('section.contact.form.errorMessage')} 
            />
          </div>
        </section>
        <Divider color="green" className={styles.divider}/>
    </main>
  )
}

export default ContactPage;
