import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Slider from 'react-slick';
import './index.scss';
// import SanAgustinLogo from '../../assets/testimonies/sanagustin.png';
// import ManquehueLogo from '../../assets/testimonies/ssccmanquehue.jpeg';
// import LosAndesLogo from '../../assets/testimonies/losandes.png';
import { useTranslation } from 'react-i18next';

const CarouselComponent = () => {
	// const settings = {
	// 	dots: true,
	// 	className: 'testimonies_slider',
	// 	centerMode: true,
	// 	infinite: true,
	// 	centerPadding: '60px',
	// 	slidesToShow: 3,
	// 	speed: 400,
	// 	draggable: true,
	// };
	const [t] = useTranslation();
	return (
		<div className="container-carousel">
			{/* <Slider {...settings} dots={true} style={{ height: '400px' }}> */}
				{/* <div className="slide_content">
					<div className="carrousel_description">
						{t('section.testimonials.description1')}
					</div>
					<div className="carrousel_image_wrapper">
						<img src={ManquehueLogo} alt="placeholder 1" />
						<h5>
							<b>{t('section.testimonials.name')}</b>
							{t('section.testimonials.position1')}
						</h5>
					</div>
				</div>
				<div className="slide_content">
					<div className="carrousel_description">
					{t('section.testimonials.description2')}
					</div>
					<div className="carrousel_image_wrapper">
						<img src={SanAgustinLogo} alt="placeholder 1" />
						<h5>
							<b>{t('section.testimonials.name2')}</b>
							{t('section.testimonials.position2')}
						</h5>
					</div>
				</div>
				<div className="slide_content">
					<div className="carrousel_description">
					{t('section.testimonials.description3')}
					</div>
					<div className="carrousel_image_wrapper">
						<img src={LosAndesLogo} alt="placeholder 1" />
						<h5>
							<b>{t('section.testimonials.name3')}</b>
							{t('section.testimonials.position3')}
						</h5>
					</div>
				</div> */}
				<div className="slide_content">
					<div className="carrousel_description">
						{t('section.testimonials.description1')}
					</div>
					<div className="carrousel_image_wrapper">
						{/* <img src={ManquehueLogo} alt="placeholder 1" /> */}
						<h5>
							<b>{t('section.testimonials.name')}</b>
							{t('section.testimonials.position1')}
						</h5>
					</div>
				</div>
				<div className="slide_content">
					<div className="carrousel_description">
					{t('section.testimonials.description2')}
					</div>
					<div className="carrousel_image_wrapper">
						{/* <img src={SanAgustinLogo} alt="placeholder 1" /> */}
						<h5>
							<b>{t('section.testimonials.name2')}</b>
							{t('section.testimonials.position2')}
						</h5>
					</div>
				</div>
				<div className="slide_content">
					<div className="carrousel_description">
					{t('section.testimonials.description3')}
					</div>
					<div className="carrousel_image_wrapper">
						{/* <img src={LosAndesLogo} alt="placeholder 1" /> */}
						<h5>
							<b>{t('section.testimonials.name3')}</b>
							{t('section.testimonials.position3')}
						</h5>
					</div>
				</div>
			{/* </Slider> */}
		</div>
	);
};

export default CarouselComponent;
