import modoBUPImage from '../../assets/modoBUP.svg';
import dataImage from '../../assets/sociograma.svg'
import sociogramImage from '../../assets/img/sociogram2.svg';
import sociogram1 from '../../assets/img/sociogram1.svg';
import ComputerImage from '../../assets/comp.png';
import AccionaImage from '../../assets/img/acciona.svg';
import sociogram3 from '../../assets/img/sociogram3.svg';
import stepSurvey from '../../assets/illu-survey.svg';
import stepProcess from '../../assets/illu-process.svg';
import stepSociogram from '../../assets/illu-sociogram.svg';
import ResourcesImage from '../../assets/Recursos.svg';
import content from '../../assets/img/recursos1.svg';
import DCEImage from '../../assets/img/diagnostico.svg';
import mediaSources from '../../assets/img/recursos2.svg';
import resourses from '../../assets/img/recursos3.svg';

export const cardImages = {
    'braveup-data': dataImage,
    'modo-braveup': modoBUPImage,
    'braveup-acciona': ResourcesImage,
    'braveup-resources': resourses,
    'braveup-dce': modoBUPImage
}

export const featureDetailsImage = {
    "braveup-data": {
        home: sociogramImage,
        description: {
            "image-1": sociogram1,
            "image-2": sociogramImage,
            "image-3": sociogram3,
        },
        reasons: {
            "image-1": modoBUPImage,
            "image-2": dataImage,
            "image-3": null
        }
    },
    "modo-braveup": {
      home: ComputerImage,
      description: {
          "image-1": stepSurvey,
          "image-2": stepProcess,
          "image-3": stepSociogram,
      },
      reasons: {
          "image-1": modoBUPImage,
          "image-2": dataImage,
          "image-3": ResourcesImage
      }
    },
    "braveup-acciona": {
      home: AccionaImage,
      description: {
          "image-1": stepSurvey,
          "image-2": stepProcess,
          "image-3": stepSociogram,
      },
      reasons: {
          "image-1": null,
          "image-2": null,
          "image-3": null
      }
    },
    "braveup-resources": {
      home: resourses,
      description: {
          "image-1": content,
          "image-2": mediaSources,
          "image-3": resourses,
      },
      reasons: {
          "image-1": modoBUPImage,
          "image-2": dataImage,
          "image-3": ResourcesImage
      }
    },
    "braveup-dce": {
      home: DCEImage,
      description: {
          "image-1": stepSurvey,
          "image-2": stepProcess,
          "image-3": null,
      },
      reasons: {
          "image-1": modoBUPImage,
          "image-2": dataImage,
          "image-3": dataImage
      }
    },
}