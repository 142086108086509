import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_ES, TRANSLATIONS_EN } from '../../translations';

const { NODE_ENV } = process.env;
const resources = {
  es: {
    translation: TRANSLATIONS_ES,
  },
  en: {
    translation: TRANSLATIONS_EN,
  },
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    
    interpolation: {
      escapeValue: false,
    },
    debug: NODE_ENV === 'development',
  });

export default i18n;