import FunctionCard from './function-card';
import { useTranslation } from 'react-i18next';
// import functions from './functions'
import { cardImages } from '../../../data/images/images';
import styles from './functions.module.scss';

const Functions = () => {
	const [t, i18n] = useTranslation();
  const lang = i18n.language
  const features = ['braveup-data', 'modo-braveup', 'braveup-resources']
  if(lang.startsWith('es')) {
    features.push('braveup-dce')
    features.push('braveup-acciona')
  }

	return (
		<section id="functions-newHome" className={styles.container}>
			<div className={styles.content_wrapper}>
				<h2>{t('section.newhome.features.title')}</h2>
				<ul className={ lang.startsWith('es') ? `${styles.featlist}`: `${styles.featlist_eng}`}>
					{
						features.map(feature => (
							<FunctionCard
                key={feature}
                title={t(`section.newhome.features.items.${feature}.title`)}
                description={t(`section.newhome.features.items.${feature}.description`)}
								url={feature}
                srcImage={ cardImages[feature] } 
              />
						))
					}
				</ul>
			</div>
		</section>
	);
};

export default Functions;
