/* eslint-disable react/prop-types */
import styles from './divider.module.scss';
import classNames from 'classnames';

const Divider = ({ color, reverse }) => {
	const classDivider = classNames(`${styles.divider}`,{
		[styles.green]: color === 'green',
		[styles.violet]: color === 'violet',
		[styles.reverse]: reverse === true,
		[styles.mix]: color === 'mix',
	});
	return (
		<span className={classDivider}>
		</span>
	);
};

export default Divider;
