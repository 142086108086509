/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './link-social.module.scss';

const LinkSocial = ({ href, icon }) => {
	return (
		<div>
			<a className={styles.link} href={href} target="_blank" rel="noreferrer">
				<FontAwesomeIcon icon={icon} />
			</a>
		</div>
	);
};

export default LinkSocial;
