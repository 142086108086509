import styles from './images.wrapper.module.scss'
import startUp from '../../assets/logos/start_up.svg'
import elab from '../../assets/logos/elab.svg'
import started from '../../assets/logos/started.svg'
import infoDataAwards from './dataAwards'
import { useTranslation } from 'react-i18next'

const ImagesWrapper = () => {
  const [t] = useTranslation()
  return (
    <div className={styles.container}>
       <div className={styles.main_awards}>
        {infoDataAwards.map((data, idx) => (
          <div key={idx} className={styles.logo_wrapper}>
            <img src={data.img} alt="image"/> 
            <p>{t(data.text)}</p>  
          </div>
          ))}
       </div>  
       <div className={styles.secondary_awards}>
        <h3>{t('contact.supportTitle')}</h3>
        <div>
            <img src={startUp} alt="Start Up Chile" className={styles.startup_img}/> 
            <img src={elab} alt="Elab" className={styles.elab_img}/>  
            <img src={started} alt="Started" className={styles.started_img}/> 
        </div>
       </div>
    </div>
  )
}

export default ImagesWrapper