import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { Link as RouterLink } from "react-router-dom";
import i18n from '../../configs/i18n';

import styles from './nav-list.module.scss';

const NavList = () => {
	const [t] = useTranslation();
	const userLang = i18n.language;
	
	return (
		<ul className={styles.list}>
			<li className={styles.list_element}>
			      <RouterLink to='/'>
							<Link
								activeclass="active"
								to= '/#functions-newHome'
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								<span className={styles.text}>{t('nav.function')}</span>
							</Link>
						</RouterLink>
			</li>
			<li className={styles.list_element}>
      		<RouterLink to='/'>
            <Link
							activeclass="active"
							to='/#logos'
							spy={true}
							smooth={true}
							offset={50}
							duration={500}
						>
							<span className={styles.text}>{t('nav.testimonials')}</span>
						</Link>
      </RouterLink>
			</li>
			<li className={styles.list_element}>
          <RouterLink to='/'>
            <Link
							activeclass="prices"
							to= '/contact'
							spy={true}
							smooth={true}
							offset={50}
							duration={500}
						>
							<span className={styles.text}>{t('nav.prices')}</span>
						</Link>

      </RouterLink>
			</li>
			<li className={styles.list_element}>
				<a  
				    href={userLang === 'en' ? 'https://blog.braveup.com/en/' : 'https://blog.braveup.com/' }
					className={styles.link}
					target="_blank"
					rel="noreferrer"
				>
					<span className={styles.text}>{t('nav.blog')}</span>
				</a>
			</li>
		</ul>
	);
};

export default NavList;
