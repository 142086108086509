import { Link } from "react-router-dom"
import styles from './large-link-button.module.scss'
import semiCircleIcon from '../../assets/Icons/semi_circle.png'

const LargeLinkButton = ({ path, text, textColor, backgroundColor }) => {
  return (
    <div className={styles.container}>
        <Link 
            to={ path } 
            className={styles.link}
            style={{
                color: textColor,
                backgroundColor
            }}
        >
          <img src={semiCircleIcon} className={styles.image}/>
          <span>
            {text}              
          </span>
        </Link>
    </div>
  )
}

export default LargeLinkButton
