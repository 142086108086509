import gsvCup from '../../assets/logos/edtech.svg'
import corfo from '../../assets/logos/corfo.svg'
import holon from '../../assets/logos/holon.svg'

const infoDataAwards = [
    {
      img: holon,
      text: 'contact.infoAward.text1'
    },
    {
      img: gsvCup,
      text: 'contact.infoAward.text2'
    },
    {
      img: corfo,
      text: 'contact.infoAward.text3'
    },
]

export default infoDataAwards