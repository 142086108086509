export const TRANSLATIONS_EN = {
    nav: {
        solution:'Solution',
        function:'Features',
        testimonials: 'Testimonials',
        prices: 'Demo',
        blog: 'Blog',
        signIn: 'Log in',
        selectLanguage:'Select a Language',
        back:'back'
    },
    whatsApp: {
      replyTime: 'Typically replies within a hour',
      message: 'How can I help?',
      inputPlaceHolder: 'type a message',
      sendButtonText: 'Send'
    },
    section: {
      'braveup-data': {
        home: {
            title: 'BRAVE UP! DATA',
            subTitle: 'Strengthened Sociogram',
            description: "Within our continuous commitment to fostering safe and uplifting school environments, it's my pleasure to introduce BRAVE UP! Sociogram. This essential resource is crafted to promote positive interactions within your classrooms and provide crucial support to every student.",
            noImage:'Imagen Computador'
        },
        description: {
            title: 'What does the Sociogram do?',
            description:"",
            items:{
                title:'Advanced detection',
                description:'Our platform utilizes evidence-based technology to identify patterns of potentially problematic behavior, providing early alerts about coexistence situations before they escalate.',
                title1:'Continuous monitoring',
                description1:'Maintain constant surveillance over interactions in your class, allowing you to better understand the dynamics and individual needs of each student.',
                title2:'Work groups',
                description2:'We provide activities designed to encourage active participation and hands-on learning. From group dynamics to individual exercises, our resources offer versatile tools for any educational setting.',
            }
        },
        reasons: {
            title: 'Why BRAVE UP?',
            subTitle:'',
            items:{
                title: 'Quality instrument',
                description: 'We design our sociogram questions based on international best practices and collaboration with partner schools. With more than 45 thousand students who have successfully responded, we guarantee a methodologically validated instrument.',
                title1: 'Commitment to the school community',
                description1: 'We believe in the importance of listening to each member of the school community. With the participation of more than 250 schools, we understand that they are the ones who best know their environment and educational experience.',
                title2: null,
                description2: null
            }
        },
      },
    'modo-braveup': {
      home: {
          title: 'BRAVE UP! MODE',
          subTitle: 'Private comunication channel',
          subTitle1:'Between student and counselors.',
          description: 'A communication channel that revolutionizes the way we address school community challenges. This innovative feature is part of the BRAVE UP! platform and stands as an essential tool for strengthening trust bonds within your educational community.',
          noImage:'Imagen Computador'
      },
      description: {
          title: 'What does BRAVE UP! Mode offer?',
          description:"",
          items:{
              title:'Reliable Anonymous Reporting',
              description:"We offer a secure and confidential space where members of your school community can communicate directly with the counselor's team. Anonymity encourages openness and facilitates the effective addressing of sensitive situations.",
              title1:'Direct Communication among your students, educational teams, and counselors',
              description1:'We eliminate barriers by enabling direct communication between students, and counselors. BRAVE UP! Mode serves as an efficient channel for sharing concerns, instances of harassment, or any issue requiring intervention.',
              title2:'Fast and Effective Response',
              description2:'With BRAVE UP! Mode, concerns are promptly addressed. This enables a fast and effective response, tackling community challenges before they escalate.',
          }
      },
      reasons: {
          title: 'Why use BRAVE UP! Mode?',
          subTitle:'',
          items:{
              title: 'Trustworthiness',
              description: 'Through implementing an anonymous reporting channel, we cultivate a culture of trust, empowering school community members to safely voice their concerns.',
              title1: 'BRAVE UP! Integration',
              description1: "BRAVE UP! Mode seamlessly integrates with the comprehensive vision of BRAVE UP!, enhancing students' mental and emotional well-being and contributing to a safer and more positive educational environment.",
              title2: 'Immediate Results',
              description2: 'Supported by the experience of schools relying on BRAVE UP!, BRAVE UP! Mode has demonstrated itself as an effective tool for improving communication and resolving community conflicts.'
          }
      },
      },
      'braveup-acciona': {
        home: {
            title: 'Brave up! Acciona',
            subTitle: 'Tu guía virtual',
            description: 'Nos complace presentarte BRAVE UP! ACCIONA, nuestro Sistema de Recomendación diseñado para unir el mundo de las redes y los recursos educativos, específicamente enfocado en fortalecer la convivencia escolar.',
            noImage: 'Sistema IA de Recomendación educativo'
        },
        description: {
            title: '¿Qué ofrece Acciona?',
            description: '',
            items:{
                title: 'Conexiones relevantes',
                description: 'Acciona utiliza la información proporcionada por expertos para crear conexiones significativas entre las necesidades específicas de la convivencia escolar y los recursos educativos disponibles en nuestra plataforma.',
                title1: 'Asesoramiento Especializado',
                description1: 'Gracias a la intervención directa de expertos, Acciona brinda asesoramiento especializado sobre qué recursos son más apropiados para abordar situaciones particulares, creando un enfoque personalizado y efectivo.',
                title2: 'Eficiencia en la toma de decisiones',
                description2: 'Al tener acceso a las recomendaciones de expertos a través de Acciona, los educadores y miembros de la comunidad escolar pueden tomar decisiones informadas y eficientes para mejorar la convivencia en su entorno educativo.'
            }
        },
        reasons: { },
    },
    'braveup-dce': {
        home: {
            title: 'Brave up! DCE',
            subTitle: 'Diagnóstico de convivencia escolar',
            description: 'En el constante esfuerzo por construir entornos escolares seguros y positivos, nos complace presentarte el Diagnóstico de Convivencia Escolar, la herramienta definitiva para mejorar la convivencia en tus cursos y brindar el apoyo necesario a cada estudiante.',
            noImage: 'Diagnostico de convivencia escolar'
        },
        description: {
            title: '¿Qué hace el Diagnóstico de Convivencia Escolar??',
            description: '',
            items:{
                title: 'Evaluación integral',
                description: 'Su objetivo es facilitar la generación de planes de intervención focalizados en aquellas áreas que presenten mayores oportunidades de mejora, optimizando y reorientando la asignación de recursos y esfuerzos por parte de los equipos de convivencia.',
                title1: 'Reportabilidad',
                description1: 'Permiten comprender las percepciones de docentes, asistentes de la educación, estudiantes y familias en torno a la convivencia en la escuela. Esto refuerza nuestro compromiso con relevar la convivencia como un aspecto clave y constitutivo del proceso de enseñanza y aprendizaje.',
            }
        },
        reasons: {
            title: '¿Por qué elegir BRAVE UP! Diagnóstico de Convivencia Escolar',
            subTitle: '',
            items: {
                title: 'Enfoque en el bienestar socioemocional',
                description: 'Nuestra herramienta prioriza la comprensión profunda del bienestar socioemocional, abordando aspectos cruciales para el desarrollo integral de los estudiantes, docentes y personal administrativo. Al elegir Brave Up, te comprometes a evaluar y mejorar la salud mental y emocional en tu comunidad educativa.',
                title1: 'Gestión de la Convivencia como Elemento Trascendental',
                description1: 'Brave Up destaca la gestión de la convivencia como un elemento trascendental en la toma de decisiones. Nuestro instrumento te brinda la capacidad de evaluar la participación, la democracia y la implementación de programas específicos, permitiéndote tomar decisiones informadas para mejorar el clima escolar y la convivencia.',
                title2: 'Instrumento de Calidad Validado Metodológicamente',
                description2: 'Diseñamos nuestras preguntas de sociograma basándonos en las mejores prácticas internacionales y en la colaboración con colegios asociados. Con más de 250 instituciones que han respondido exitosamente, garantizamos un instrumento validado metodológicamente. Al elegir Brave Up, confías en un proceso respaldado por la experiencia y el éxito de numerosas instituciones educativas.'
            }
        }
    },
    "braveup-resources": {
        home: {
            title: 'BRAVE UP! Resources',
            subTitle: 'The power of BRAVE UP! activities',
            subTitle1: '',
            description: 'Discover the essence of effective education with BRAVE UP! Our platform provides specialized resources, from inspirational videos to activities designed to connect with the reality of students, families, and educational teams. We offer tools that transform daily challenges into healthy and positive school environments. Join the educational revolution today!',
            noImage: 'No image'
        },
        description: {
            title: 'What will you find in our resources?',
            description: '',
            items:{
                title: 'Powerful Content',
                description: 'Immerse yourself in educational content that directly addresses the issues students face in their daily lives. From relevant topics to practical solutions, our resources are designed to be informative and transformative.',
                title1: 'Inspirational Videos - Coming soon',
                description1: 'Through captivating visuals, we lead the educational community on a journey of reflection and discovery. Our videos aim not only to educate but also to inspire and motivate, leaving a lasting impact.',
                title2: 'Practical Activities',
                description2: 'We provide activities designed to encourage active participation and hands-on learning. From group dynamics to individual exercises, our resources offer versatile tools for any educational setting',
            }
        },
        reasons: {
            title: 'Why use BRAVE UP! Resources?',
            subTitle: '',
            items: {
              title: 'Focus on Socioemotional Well-being',
              description: 'Our resources are carefully crafted to connect with the real experiences and challenges of students, thus fostering more meaningful learning.',
              title1: 'Integration with BRAVE UP!',
              description1: 'By being part of the BRAVE UP! platform, our resources align perfectly with our mission to strengthen the mental and emotional health of students, creating a comprehensive educational ecosystem.',
              title2: 'Proven Results',
              description2: 'Backed by schools and educators who trust BRAVE UP!, our resources have proven to be effective tools for improving understanding and addressing the everyday issues that affect students.',
              
            }
        }
    },
    callToAction: {
        title: 'Try BRAVE UP! in your school.',
        action: "I'm ready to take action!"
      },
      newhome: {
          home:{
              title: 'BRAVE UP!',
              subTitle: "Let's strengthen our",
              subTitle1: 'school community',
              subTitle2: '',
              description: 'helps K-12 schools detect, predict, and prevent cases of microaggressions, bullying, and cyberbullying.',
              noImage: 'brave-up-convivencia-escolar.jpg'
          },
          testimonials: { 
            title: 'Testimonials',
              first: {
                name: "Veronica",
                text: "I want to thank you for creating this amazing software to help keep our students safe and to congratulate the team for representing the Latino community",
                role: "Counselor",
                location: "Texas, United States"
            },
              second:   {
                name: "Sarah",
                text: "I was very impressed with the user interface, it was very easy and intuitive to use",
                role: "Counselor",
                location: "Oklahoma, United States"
            },
              third: {
                name: "Gwendolyn",
                text: "I have been an educator for over 20 years and have used multiple education software and I have never seen such an awesome tool that addresses this specific need for us counselors",
                role: "Counselor",
                location: "Illinois, United States"
              }
          },
          logos: {
              noFigure: 'rectangule',
              description: 'Nuestro compromiso con la excelencia crece con más de 50.000 usuarios',
              items: {}
          },
          features: {
              title: "BRAVE UP! Features",
              items: {
                'braveup-data': {
                  title: 'SOCIOGRAMS',
                  description: 'Improve School Community with Effective Strategies, Real-time Data, Reports, and an Annual Methodology.',
                },
                'modo-braveup': {
                  title: 'BRAVE UP! MODE',
                  description: 'Secure channel for school community challenges essential for reinforcing trust within educational community.',
                },
                'braveup-acciona': {
                  title: 'ACCIONA',
                  description: 'Sistema que conecta redes y recursos educativos, fortaleciendo la convivencia escolar al unir expertos con la comunidad para recomendaciones efectivas.',
                },
                'braveup-resources': {
                  title: 'RESOURCES',
                  description: 'BRAVE UP! propels education, uniting students, families, and educators, turning challenges into positive school atmospheres.',
                },
                "braveup-dce": {
                  title: 'DIAGNÓSTICO CONVIVENCIA ESCOLAR',
                  description: 'Herramienta clave para mejorar la convivencia, obtener una radiografía de tus cursos y apoyar a cada estudiante, fortaleciendo entornos escolares seguros y positivos.',
                }
              }
          },
          data: {
              title: 'Student',
              value: '+ 40.000',
              description: 'We explore the educational essence through sociograms, turning information into solutions that promote a healthy and motivating educational environment',
              
              title1: 'Victims',
              value1: '8,7%',
              description1: 'The 8.7% of students identify themselves as victims, a revealing statistic that drives our mission to create a school environment that is safe, inclusive, and free from harassment.',
              
              title2: 'Countries',
              value2: '+ 7',
              description2: 'We unite hearts across over 7 countries, enhancing school management and fostering harmonious relationships. We ignite inspiration and strive for excellence within each educational community.',
              
              title3: 'Intervened classes',
              value3: '+ 1.000',
              description3: 'We enhance the educational experience across more than 1,000 classrooms, improving both management and school community through inspiration and excellence',
          },
      },
    },
    contact: {
      title: 'Take the step to transform your School Community!',
      supportTitle: 'Supported by the accelerators START UP CHILE, STARTED, and eLab',
      infoAward: {
        text1: 'Selected as one of the most promising EdTech startups in North America on the annual list The North America EdTech by HolonIQ.',
        text2: "Selected as Elite 200. Semifinalist in the World's Largest Competition for EdTech Startups (GSV Cup Elite 200).",
        text3: 'Winners of the CORFO fund "Create and Validate"; for the development and impact of the BRAVE UP! platform.'
      },
      form:{
        buttonText: 'Get Demo',
        titleForm: 'Request Your Demo Today',
        name: 'Name',
        email: 'Email',
        country:'Country',
        position: 'What is your role?',
        school: 'School',
        phone:'Phone Number',
        products:'What product are you interested in?',
        howKnows:'How did you hear about us?',
        sendButton: 'Send Message',
        register:'Register',
        successMessage:'Thank you for requesting your demo, someone from the BRAVE UP! team will contact you shortly',
        errorMessage: 'An error has occurred, please reload the page and try again'
          },
        label: {
          name: 'Name',
          email: 'Email',
          position: 'Position',
          other: 'Other',
          school: 'School',
          phone: 'Phone',
          country: 'Country',
          products: 'Features',
          options: 'Tell us',
          send: 'Send'
        },
        roles: {
          owner: 'Owner / distric',
          dean: 'Principal',
          counselor: 'Counselor',
          teacher: 'Teacher',
          other: 'Other'
        },
        functions: {
          sociogram: 'Sociogram',
          modoBup: 'BRAVE UP! Mode',
          action: null,
          resources: 'Resources',
          survey: null
        },
        options: {
          social:'Social Network',
          engine: 'Search engine (Google, Bing, Safari)',
          email: 'Email',
          recommendation: 'Referal',
          web: 'Website',
          event: 'Conferences or events',
          advertising: 'Advertising'
        }
  },
    footer: {
        brand: 'All rights reserved',
        instagram: 'https://www.instagram.com/braveup_'
    },
}

 