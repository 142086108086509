/* eslint-disable react/prop-types */
import useAnalyticsEventTracker from '../../configs/googleAnalitycs';
import styles from './link-button.module.scss';

const LinkButton = ({ buttonText, href }) => {
	const gaEventTracker = useAnalyticsEventTracker('Log in')
	return (
		<a
			className={styles.button_link}
			onClick={() => gaEventTracker('Log')}
			href={href}
			target="_blank"
			rel="noreferrer"
		>
			<span>{buttonText}</span>
		</a>
	);
};

export default LinkButton;
