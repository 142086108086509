import styles from './description.module.scss'
import halfCircle from '../../../assets/float-3.svg';
import { useTranslation } from 'react-i18next';

const DescriptionComponent = ({ name, images }) => {
  const [t] = useTranslation();

  // Obtenemos las claves de items
  const items = Object.keys(t(`section.${name}.description.items`, { returnObjects: true }));
  
  return (
    <section id={name} className={styles.container}>
      <div className={styles.content_wrapper}>
        <h2>{t(`section.${name}.description.title`)}</h2>
        <div className={styles.info_steps}>
          {items
            .filter((_, i) => i % 2 === 0) // Filtra los índices pares para los títulos
            .map((titleKey, i) => {
              const descriptionKey = items[i * 2 + 1]; // Índice impar correspondiente a la descripción
              const isEven = i % 2 === 0;

              return (
                <div key={titleKey} className={styles[`step${!isEven ? '_left' : '_right'}`]}>
                  <img src={images[`image-${i + 1}`]} className={styles.step_image} />
                  <div className={styles.text_wrapper}>
											<h3 className={styles.step_title}>{t(`section.${name}.description.items.${titleKey}`)}</h3>
											<p className={styles.step_description}>
												{t(`section.${name}.description.items.${descriptionKey}`)}
											</p>
									</div>
                </div>
              );
            })}
        </div>
        <img alt='half circle' src={halfCircle} className={styles.halfCircle} />
      </div>
    </section>
  );
};

export default DescriptionComponent;
