const testimonialsEs = [
    {
        name: "section.newhome.testimonials.first.name",
        text:"section.newhome.testimonials.first.text",
        role: "section.newhome.testimonials.first.role",
        location: "section.newhome.testimonials.first.location"
    },
    {
        name: "section.newhome.testimonials.second.name",
        text:"section.newhome.testimonials.second.text",
        role: "section.newhome.testimonials.second.role",
        location: "section.newhome.testimonials.second.location"
    },
    {
        name: "section.newhome.testimonials.third.name",
        text:"section.newhome.testimonials.third.text",
        role: "section.newhome.testimonials.third.role",
        location: "section.newhome.testimonials.third.location"
    },
]

export default testimonialsEs