import { useParams } from 'react-router-dom';
import styles from './functions-pages.module.scss'
import HomeComponent from '../../components/functionSectionComponent/home';
import DescriptionComponent from '../../components/functionSectionComponent/description';
import { Divider } from '../../components';
import { featureDetailsImage } from '../../data/images/images';
import ReasonsComponent from '../../components/functionSectionComponent/reasons';


const FunctionPages = () => {
  const { name } = useParams()

  const images = featureDetailsImage[name]
  
  return (
    <main className={styles.container}>
       <HomeComponent name={name} images={images.home} />
       <Divider color='green' />
       <DescriptionComponent name={name} images={images.description} />
        {name !== 'braveup-acciona' ?
          <>
            <Divider color='violet' />
            <ReasonsComponent name={name} images={images.reasons} />
            <Divider color='violet' reverse /> 
          </>
         : null}
    </main>
  )
}

export default FunctionPages