/* eslint-disable react/prop-types */

import styles from './lang-list.module.scss';


const LangList = ({ onClick, icon, label}) => {
	
	return (
		<div className={styles.lang_list}>
			<a  onClick={onClick}>
				<span className={styles.flag}>
					{icon}
				</span>
				<span className={styles.language}>
					{label}
				</span>
			</a>
		</div>
	);
};

export default LangList;
