import { Home, Functions, Data, Logos } from '../../sections/newHome';
import styles from './main.module.scss';
import Divider from '../../components/Divider';
import CallToAction from '../../sections/newHome/callToAction';

const newHomePage = () => {
  return (
    <main className={styles.container}>
      <Home />
      <Divider color='green' />
      <Data />
			<Divider color='violet' />
      <Functions />
			<Divider color='violet' reverse />
      <Logos />
      <Divider color='green' reverse/>
      <CallToAction/>
      <Divider color='green' />
    </main >
  );
};

export default newHomePage;
