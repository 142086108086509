import Slider from "react-slick";
import styles from './new_carousel.module.scss'
import PropTypes from 'prop-types'
import ArrowNext from '../../assets/Icons/arrow_right.png'
import ArrowPrev from '../../assets/Icons/arrow_left.png'
import SemiCircleIcon from '../../assets/Icons/semi_circle.png'
import { useTranslation } from "react-i18next";

const ArrowLeft = (props) => (
  <div className={styles.button_wrapper}>
    <button
        {...props}
        className={styles.prev}
    >
      <img src={ArrowPrev}/>
    </button>
  </div>
);
const ArrowRight = (props) => (
  <div className={styles.button_wrapper}>
    <button
        {...props}
        className={styles.next}
    >
      <img src={ArrowNext}/>
    </button>
  </div>
);

const NewCarousel = ({ data }) =>{
  const [t] = useTranslation()
  const settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
  };

  return (
    <>
      <div className={styles.container}>
      <img src={SemiCircleIcon} className={styles.semi_circle_icon} />
        <Slider {...settings} className={styles.slider}>
          {data.map((element, index) => (
              <div key={index} className={styles.content_wrapper}>
                  <div className={styles.text_wrapper}>
                      <p>{t(element.text)}</p>
                  <hr className={styles.divider_text}/>
                      <h4>{t(element.name)}</h4>
                      <h4 className={styles.role_text}>{t(element.role)}, {t(element.location)}</h4>
                  </div>
              </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

NewCarousel.propTypes = {
    data: PropTypes.array.isRequired,
  };

export default NewCarousel;