/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import styles from './newform.module.scss'
import { Button, Form, Input, Select } from 'antd';
import { useState } from 'react';
import { useSendFormData } from '../../hooks/useSendFormData';



const NewContactForm = ({ googleSheetUrl }) => {
const [t, i18n] = useTranslation()
const {sendDataForm} = useSendFormData()
const [loading, setLoading] = useState(false)
const [setResponse] = useState(null)
const lang = i18n.language



  const roles = [
    { id: 1, name: 'contact.roles.owner' },
    { id: 2, name: 'contact.roles.dean' },
    { id: 3, name: 'contact.roles.counselor' },
    { id: 4, name: 'contact.roles.teacher' },
    { id: 5, name: 'contact.roles.other' },
  ];

  const functions = [
    { id: 1, name: 'contact.functions.sociogram' },
    { id: 2, name: 'contact.functions.modoBup' },
    { id: 3, name: 'contact.functions.action' },
    { id: 4, name: 'contact.functions.resources' },
    { id: 5, name: 'contact.functions.survey' },
  ];

  const options = [
    { id: 1, name: 'contact.options.social' },
    { id: 2, name: 'contact.options.engine' },
    { id: 3, name: 'contact.options.email' },
    { id: 4, name: 'contact.options.recommendation'},
    { id: 5, name: 'contact.options.web' },
    { id: 6, name: 'contact.options.event' },
    { id: 7, name: 'contact.options.advertising' },
  ];

  const filterFunctionIfEng = functions.filter((funct) => funct.name !== 'contact.functions.action')
  .filter(funct => funct.name !== 'contact.functions.survey')

  const webFunction = lang === 'es' ? functions : filterFunctionIfEng

  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Position: '',
    Otro: '',
    School: '',
    Phone: '',
    Country: '',
    Functions: [],
    Options:'',


  });
  const { Position } = formData
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSelectChange = (value, obj) => {
    const { name } = obj
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  }
  const handleSelectChangeMulti = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Functions: value
    }));
  }
 
  const onSubmit = async () => {
      const formDataToSend = new FormData();
       formDataToSend.append('Name', formData.Name);
       formDataToSend.append('Email', formData.Email);
       formDataToSend.append('Position', formData.Position);
       formDataToSend.append('Otro', formData.Otro);
       formDataToSend.append('School', formData.School);
       formDataToSend.append('Phone', formData.Phone);
       formDataToSend.append('Country', formData.Country);
       formDataToSend.append('Functions', formData.Functions);
       formDataToSend.append('Options', formData.Options);
       sendDataForm(formData)
       setLoading(true)
       await fetch(googleSheetUrl, {
         method: 'POST',
         body: formDataToSend
       })
       .then((response) => {
         setResponse(response)
       })
       .catch((error) => console.log(console.error(error)))
       .finally(() => setLoading(false))

    
  }

  return (
    loading ? <h1>Loading.....</h1>
     :
     <div className={styles.form_container}>
      <Form 
      onFinish={onSubmit}
      className={styles.form_wrapper}
      labelCol={{
        span: 8,
      }}
    >
      <Form.Item  label={t('contact.label.name')} name='Name' >
        <Input  placeholder={t('contact.form.name')} name='Name' 
        onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item  label={t('contact.label.email')} name='Email'>
        <Input  placeholder={t('contact.form.email')} name='Email'
         onChange={handleInputChange}
         />
      </Form.Item>
      <Form.Item name='Position'  label={t('contact.label.position')}>
        <Select name='Position'
        onChange={handleSelectChange}
        placeholder={t('contact.form.position')}
        >
          {roles.map((role) => (
              <Select.Option name='Position' key={role.id} value={t(role.name)}>
              {t(role.name)}
        </Select.Option>
          ))}
        </Select>
      </Form.Item>

        {Position === 'Otro' || Position === 'Other' ?
            <Form.Item label={t('contact.label.other')} name='Otro'>
            <Input placeholder={t('contact.form.position')} onChange={handleInputChange} name='Otro' />
            </Form.Item> : null
          }

      <Form.Item name='School' label={t('contact.label.school')}>
        <Input placeholder={t('contact.form.school')} onChange={handleInputChange} name='School' autoComplete='true' />
      </Form.Item>

      <Form.Item name='Phone'  label={t('contact.label.phone')}>
        <Input placeholder={t('contact.form.phone')} onChange={handleInputChange} name='Phone' type='number' autoComplete='true' />
      </Form.Item>

      <Form.Item name='Country' label={t('contact.label.country')}>
      <Input placeholder={t('contact.form.country')} onChange={handleInputChange}  name='Country'  autoComplete='true' />
      </Form.Item>

      <Form.Item name='Functions' label={t('contact.label.products')}>
        <Select 
        name='Functions'
        mode='multiple'
        onChange={handleSelectChangeMulti}
        placeholder={t('contact.form.products')}
        >
          {webFunction.map((feature) => (
              <Select.Option name='Functions' key={feature.id} value={t(feature.name)}>
              {t(feature.name)}
        </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='Options'   label={t('contact.label.options')}>
        <Select 
          name='Options'
          onChange={handleSelectChange}
          placeholder={t('contact.form.howKnows')}
        >
          {options.map((option) => (
              <Select.Option name='Options' key={option.id} value={t(option.name)}>
              {t(option.name)}
        </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className={styles.form_button_wrapper}>
        <Button className={styles.form_button}  type='primary' htmlType='submit'>{t('contact.label.send')}</Button>
      </Form.Item>
    </Form>
     </div>
   
  )
}

export default NewContactForm