
import InnovaSchools from '../../../assets/logos/users/innova_shcools.svg'
import SanAgustin from '../../../assets/logos/users/san_agustin.svg'
import Cognita from '../../../assets/logos/users/cognita.svg'
import Montessori from '../../../assets/logos/users/montessori.svg'
import AbrahamLincoln from '../../../assets/logos/users/abraham_lincoln.svg'
import Alcazar from '../../../assets/logos/users/alcazar_logo.png'
import ColegioSanLuis from '../../../assets/logos/users/san_luis_antofagasta_logo.png'
import EthelHenckGrant from '../../../assets/logos/users/ethel_henck_grant_logo.jpeg'
import InstitutoPresidenteErrazuriz from '../../../assets/logos/users/instituto_presidente_errazuriz_logo.png'
import Eleuterio from '../../../assets/logos/users/eleuterio_logo.png'
import LuisFagnano from '../../../assets/logos/users/luis_fagnano_logo.png'

const usersImages = [
	{
		src: InnovaSchools,
        alt: 'Innova Schools',
        class: 'innova_schools_logo'
	},
	{
		src: SanAgustin,
        alt: 'San Agustín',
        class: 'san_agustin_logo'
	},
	{
		src: Cognita,
        alt: 'Cognita',
        class: null
	},
	{
		src: Montessori,
        alt: 'Montessori',
        class: null
	},
	{
		src: AbrahamLincoln,
        alt: 'Abraham Lincoln',
        class: 'abraham_logo'
	},	
	{
		src: EthelHenckGrant,
		alt: 'Ethel Henck de Grant',
		class: null
	},
	{
		src: InstitutoPresidenteErrazuriz,
		alt: 'Fundación Alcázar',
		class: null
	},
	{
		src: LuisFagnano,
		alt: 'Liceo Salesiano Monseñor Fagnano',
		class: null
	},
	{
		src: Alcazar,
		alt: 'Colegio Alcázar',
		class: 'alcazar_logo'
	},
	{
		src: ColegioSanLuis,
		alt: 'Colegio San Luis',
		class: null
	},
	{
		src: Eleuterio,
        alt: 'Colegio Coronel Eleuterio Ramirez Molina',
        class: null
	},
];

export default usersImages;