import ReactGA from 'react-ga';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import { Footer, Header } from './components';
import NewHomePage from './pages/newHomePage';
import ContactPage from './pages/contactPage';

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

import { useEffect } from 'react';

import FunctionPages from './pages/functionsPage';
import { useTranslation } from 'react-i18next';


const TRACKING_ID = "UA-133866763-2";

ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [t] = useTranslation()
	return (
		<Router>
			<ScrollToTop />
			<Header />
			<Routes>
				<Route path="/" element={<NewHomePage />} exact />
        <Route path='/feature/:name' element={<FunctionPages/>} />
				<Route path="/contact" element={<ContactPage />} />
        </Routes>
        <WhatsAppWidget  
          phoneNumber="+56949077003"
          companyName='BRAVE UP!'
          replyTimeText={t('whatsApp.replyTime')}
          message={t('whatsApp.message')}
          inputPlaceHolder={t('whatsApp.inputPlaceHolder')}
          sendButtonText={t('whatsApp.sendButtonText')}
        />
      <Footer />
		</Router>
	);
}

export default App;
