import styles from './home.module.scss';
import ComputerImage from '../../../assets/comp.png';
import { useTranslation } from 'react-i18next';

const newhome = () => {
  const [t] = useTranslation();

	const applyBoldStyle = (text) => {
    // Encierra el texto entre asteriscos con estilos en línea de negrita
    const parts = text.split('*');
    return parts.map((part, index) => (index % 2 === 0 ? part : <strong key={index}>{part}</strong>));
  };
  return (
    <section id="newhome" className={styles.container}>
		<div className={styles.content_wrapper}>
			<div className={styles.text_wrapper}>
				<h5>
					<b>{t('section.newhome.home.title')}</b>
				</h5>
				<h1 className={styles.title}>
				{applyBoldStyle(t('section.newhome.home.subTitle').toUpperCase())} {" "}
				{applyBoldStyle(t('section.newhome.home.subTitle1').toUpperCase())} {" "}
				{applyBoldStyle(t('section.newhome.home.subTitle2').toUpperCase())} {" "}
				</h1>
				<p>
				{applyBoldStyle(t('section.newhome.home.description'))}
				</p>
			</div>
			<img
				className={styles.image}
				alt={t('section.newhome.home.noImage')}
				src={ComputerImage}
			/>
		</div>
	</section>
  );
};

export default newhome;