import { useNavigate } from 'react-router-dom'
import styles from './function-card.module.scss'

const FunctionCard = ({ url, srcImage, title, description }) => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/feature/${url}`);
  };


  return (
    <li className={styles.container}>
        <div onClick={handleClick} className={styles.link}>
            <img src={ srcImage } alt="Data" />
            <h5 className={styles.title}>
                { title }
            </h5>
            <p className={styles.description}>
                { description }
            </p>
        </div>
    </li>
  )
}

export default FunctionCard