import styles from './logos.module.scss';
import { useTranslation } from 'react-i18next';
import usersImages from './usersData';
import { NewCarousel } from '../../../components';
import testimonialsEs from './testimonialsEs';

const Logos = () => {
  const [t, i18n] = useTranslation();
	const lang = i18n.language

  return (
    <section id="logos" className={styles.container}>
		<h2 className={styles.title}>{t('section.newhome.testimonials.title')}</h2>
		<NewCarousel data={testimonialsEs}/>
		{lang === 'es' ? 
		<div className={styles.logos_wrapper}>
			<div className={styles.description_wrapper}>
				<div className={styles.polygon_triangle}></div>
				<strong>{(t('section.newhome.logos.description'))}</strong>
			</div>
			<div className={styles.images_wrapper}>
				{
					usersImages.map((image) => {
						const classImage = image.class || 'logo_img'
						return (
						<div className={styles.logo_wrapper} key={image.alt}>
							<img src={image.src} alt={image.alt} className={styles[classImage]}/>
						</div>
					)})
				}
			</div>
		</div>
		: null}
	</section>
  );
};

export default Logos