const values = [
    {
        value: 'value',
        title: 'title',
        description: 'description'
    },
    {
        value: 'value1',
        title: 'title1',
        description: 'description1'
    },
    {
        value: 'value2',
        title: 'title2',
        description: 'description2'
    },
    {
        value: 'value3',
        title: 'title3',
        description: 'description3'
    },
]
export default values