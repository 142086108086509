import { useTranslation } from 'react-i18next'
import { LargeLinkButton } from '../../../components'
import styles from './call-to-action.module.scss'

const CallToAction = () => {
  const [t] = useTranslation()
  return (
    <section id="call-to-action-home" className={styles.container}>
     <div className={styles.content_wrapper}>
       <h2>{t('section.callToAction.title')}</h2>
       <LargeLinkButton 
         text={t('section.callToAction.action')}
         textColor="#2f3838"
         backgroundColor="#ffffff"
         path="/contact"
       />
     </div>
    </section>
  )
}

export default CallToAction