import { LinkSocial, NavList } from '../../components';
import styles from './footer.module.scss';
import {
	faInstagramSquare,
	faLinkedinIn,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { animateScroll as scroll } from 'react-scroll';
import Logo from '../../assets/braveup-Color.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();
	const [t] = useTranslation();

	return (
		<footer className={styles.footer}>
			<div className={styles.wrapper}>
			<div className={styles.navigation_wrapper}>
				<div
					className={styles.image_wrapper}
					onClick={() => scroll.scrollToTop()}
				>
					<img className={styles.image} alt="Logo Brave Up!" src={Logo} />
				</div>
				<div className={styles.list_wrapper}>
					<NavList />
				</div>
			</div>
			<div className={styles.info_wrapper}>
				<div>
					<div>
						<small>🇨🇱 Guardia Vieja 181, Providencia, Santiago.</small>
					</div>
					<div>
						<small>🇺🇸 220 E 23rd Street, 400, New York.</small>
					</div>
				</div>
				<div className={styles.right_content}>
					<div className={styles.text_wrapper}>
						<small>© {year} BRAVE UP! {t('footer.brand')}</small>
					</div>
					<div className={styles.icons_wrapper}>
						<div>
							<LinkSocial
								href="https://www.twitter.com/braveup_oficial"
								icon={faTwitter}
							/>
						</div>
						<div>
							<LinkSocial
								href="https://www.linkedin.com/company/brave-up"
								icon={faLinkedinIn}
							/>
						</div>
						<div>
							<LinkSocial
								href='https://www.instagram.com/braveup_latam/'
								icon={faInstagramSquare}
							/>
						</div>
					</div>
				</div>
			</div>
			</div>
		</footer>
	);
};

export default Footer;
