/* eslint-disable react/prop-types */
import styles from './back-button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

const BackButton = ({ buttonText }) => {
	const navigate= useNavigate();
	return (
		<button
			className={styles.button_link}
			onClick={() => navigate(-1)}
			target="_blank"
			rel="noreferrer"
		>
			<FontAwesomeIcon icon={faChevronLeft} className={styles.button_icon}/>
			<span className={styles.button_text}>{buttonText}</span>
		</button>
	);
};

export default BackButton;
