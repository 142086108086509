import { LinkButton, LangList } from '../../components';
import styles from './nav-bar.module.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/braveup-Color.svg';
import Iso from '../../assets/iso.svg';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons'
import { HashLink as Link } from 'react-router-hash-link';

const NavBar = () => {

	const [t, i18n] = useTranslation();
	const userLang = i18n.language;

	const changeSpanishLang = () => {
		i18n.changeLanguage('es')
	}

	const changeEnglishLang = () => {
		i18n.changeLanguage('en')
	}

	const [showMenu, setShowMenu] = useState(false);
	const classNameMenu = classNames(`${styles.navBar}`, {
		[styles.show]: !showMenu,
		[styles.hide]: showMenu,
	});

	const menuToggle = () => {
		setShowMenu(value => !value);
	}

	const closeMenu = () => {
		setShowMenu(false);
	}

	return (
		<nav className={classNameMenu}>
			<Link
				className={styles.logo_wrapper}
				to='/#home-newHome'
				spy={true}
				smooth={true}
				offset={50}
				duration={500}
			>
				<img className={styles.image} alt="Logo Brave Up!" src={Logo} />
				<img className={styles.iso} alt="Logo Brave Up!" src={Iso} />
			</Link>

			<div className={styles.list_wrapper}>
				<ul className={styles.list}>
					<li className={styles.list_element}>
						<RouterLink to='/'>
							<Link
								activeclass="active"
								to='/#functions-newHome'
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								<span onClick={closeMenu} className={styles.text}>{t('nav.function')}</span>
							</Link>
						</RouterLink>
					</li>
					<li className={styles.list_element}>
						<Link
							activeclass="active"
							to= '/#logos'
							spy={true}
							smooth={true}
							offset={50}
							duration={500}
						>
							<span onClick={closeMenu} className={styles.text}>{t('nav.testimonials')}</span>
						</Link>
					</li>
					<li className={styles.list_element}>
						<Link
							activeclass="prices"
							to='/contact'
							spy={true}
							smooth={true}
							offset={50}
							duration={500}
						>
							<span onClick={closeMenu} className={styles.text}>{t('nav.prices')}</span>
						</Link>
					</li>
					<li className={styles.list_element}>
						<a
							href={userLang === 'en' ? 'https://blog.braveup.com/en/' : 'https://blog.braveup.com/'}
							className={styles.link}
							target="_blank"
							rel="noreferrer"
						>
							<span className={styles.text}>{t('nav.blog')}</span>
						</a>
					</li>
				</ul>
			</div>
			<div className={styles.menu_icon} onClick={menuToggle}>
				{showMenu ?
					<FontAwesomeIcon icon={faXmark} />
					:
					<FontAwesomeIcon icon={faBars} />
				}
			</div>
			<div className={styles.actions}>
				<LinkButton buttonText={t('nav.signIn')} href="https://app.braveup.co" />
			</div>

			<div className={styles.lang_container}>
				<p className={styles.lang_title}>
					{t('nav.selectLanguage')}
				</p>
				<LangList
					label={"español"}
					icon={"🇪🇸"}
					onClick={changeSpanishLang}
				/>
				<LangList
					label={"english"}
					icon={"🇺🇸"}
					onClick={changeEnglishLang}
				/>
			</div>
		</nav>
	);
};

export default NavBar;
