import { useTranslation } from 'react-i18next'
import styles from './reasons.module.scss'


const ReasonsComponent = ({ name, images }) => {
    const [t] = useTranslation()
    
  return (
    <section id={name} className={styles.container}>
    <div className={styles.content_wrapper}>
        <div className={styles.header}>
            <h1>{t(`section.${name}.reasons.title`)}</h1>
        </div>
        <ul className={styles.featlist}>
            <li className={styles.item}>
                <img src={images["image-1"]}/>
                <h4 className={styles.title}>
                    {t(`section.${name}.reasons.items.title`)}
                </h4>
                <p className={styles.description}>
                    {t(`section.${name}.reasons.items.description`)}
                </p>
            </li>
            <li className={styles.item}>
                <img src={images["image-2"]}/>
                <h4 className={styles.title}>
                    {t(`section.${name}.reasons.items.title1`)}
                </h4>
                <p className={styles.description}>
                    {t(`section.${name}.reasons.items.description1`)}
                </p>
            </li>
        </ul>
    </div>
</section>
  )
}

export default ReasonsComponent