import { NavBar } from '../../components';
import styles from './header.module.scss';

const Header = () => {
	return (
		<header className={styles.header} id="header">
			<NavBar />
		</header>
	);
};

export default Header;
